<template>
	<div class="login">

		<!-- Login -->
		<div class="login__block active" id="l-login">
			<div class="login__block__header">
				<h3>LOGO</h3>
				Update Account Password
			</div>

			<div class="login__block__body" style='position: relative; min-height: 150px;'>

				<div class='loading-screen pt-5' style='' v-if='loading'>
					{{loading_message}}
				</div>

				<Message v-if="success.length > 0" type="success" :message="success" />

				<form @submit.prevent="validateRecoverySubmit()" data-vv-scope="login" v-if='approved'>

					<div class="form-group mb-0">
						<input :class="{'is-invalid': get_error('password').length > 0}" v-model="password" type="password" class="form-control" placeholder="New Password">
					</div>
					<span v-show="get_error('password').length > 0" class="text-danger">{{ get_error('password') }}</span>

					<div class="form-group mt-4 mb-0">
						<input :class="{'is-invalid': get_error('confirm').length > 0}" v-model="confirm" type="password" class="form-control" placeholder="Confirm Password">
					</div>
					<span v-show="get_error('confirm').length > 0" class="text-danger">{{ get_error('confirm') }}</span>
					<br/>
					<ErrorMessage class='mt-2 mb-2' v-if="pass_error.length > 0" type="error" :message="pass_error" />

					<button type='submit' class="btn btn-theme mt-4">Reset Password</button>
				</form>

				<ErrorMessage class='mt-2 mb-2' v-if="code_error.length > 0" type="error" :message="code_error" />

			</div>
			<a href='/login' class='forgot-link mt-1 mb-3'><u>Back to login?</u> <i class="zwicon-chevron-right"></i></a>
		</div>
	</div>
	
</template>

<style scoped  lang="scss">
	.loading-screen{
		background-color: rgba(20, 20, 20, 1); 
		position: absolute; 
		top: 0px; 
		left: 0px; 
		width: 100%; 
		height: 100%;
		font-size: 1.5rem;
		z-index: 9999;
	}
	.logo{
		height: 90px !important;
		width: auto !important;
		min-width: auto !important;
		box-shadow: none !important;
		-webkit-box-shadow: none !important;
	}
	.login__block{
		display: block;
	}
	.forgot-link{
		display: inline-block;
		position: absolute;
		bottom: -40px;
		left: 0px;
		width: 100%;
	}
</style>

<script>
	import store from "@/store/";

	import { ON_LOAD, OFF_LOAD, RESET_VALIDATE, RECOVER_ACCOUNT } from "@/store/actions.type";

	import Message from "@/components/General/Message";
	import ErrorMessage from "@/components/General/ErrorMessage";

	// Language / Localization
	export default {
		name: "Login",
		components: {
			Message,
			ErrorMessage
		},
		props: {
			error: {
				type: String,
				default: ""
			},
			success: {
				type: String,
				default: ""
			}
		},
		data() {
			return {
				loading: true,
				loading_message: 'Checking verification...',
				approved: false,
				recovery_code: '',
				code_error: '',
				pass_error: '',
				password: '',
				confirm: '',
				errors: {
					password: '',
					confirm: ''
				}
			}
		},
		beforeMount(){
			this.recovery_code = this.$route.params.code;
			if(this.recovery_code.length == 0){
				this.code_error = 'The verification code that you provided is invalid or missing.';
				return;
			} else {
				store.dispatch(ON_LOAD);
				store.dispatch(RESET_VALIDATE, this.recovery_code).then(() => {
					this.approved = true;
				}).catch(message => {
					console.log(message);
					if(typeof message == 'string'){
						this.code_error = message;
					} else {
						this.code_error =  'The verification code that you provided could not be verified.';
					}
				}).finally(() => {
					store.dispatch(OFF_LOAD);
					this.loading = false;
				});
			}
		},
		methods: {
			get_error(key){
				if(this.errors[key] !== undefined) return this.errors[key];
				return '';
			},
			validateRecoverySubmit(){
				this.pass_error = '';
				this.errors = {
						password: '',
						confirm: ''
					};
				// Validate password
				if(this.password.trim().length <= 1) {
					this.errors.password = "The password you entered was invalid or missing.";
					return;
				}
				// Validate password
				if(this.confirm.trim().length <= 1) {
					this.errors.confirm = "The password confirmation you entered was invalid or missing.";
					return;
				}
				// Validate password
				if(this.password != this.confirm) {
					this.errors.confirm = "The confirmation and password do not match.";
					return;
				}
				store.dispatch(RECOVER_ACCOUNT, { 'code': this.recovery_code, 'password': this.password }).then(() => {
					this.$router.push({ name: 'login', query: { time: Date.now() }, params: { success: "Your password has been updated." }});
				}).catch(message => {
					if(typeof message == 'string'){
						this.pass_error = message;
					} else {
						this.code_error =  'The verification code that you provided could not be verified.';
					}
				});
			}
		}
	}
</script>